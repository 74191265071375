<template>
    <div class="identify-account-manage">
        <TableListPageTemplate v-bind="templateConfig" :pagingConfig.sync="getAccountListParams" :totalCount="totalCount">
            <template #tableOperate="{ item }">
                <el-link type="success" :underline="false" @click="editAccount(item)">编辑</el-link>
                <el-link type="danger" :underline="false" @click="deleteAccount(item)">删除</el-link>
            </template>
        </TableListPageTemplate>
        <Dialog :title="txtTitle" ref="operateAccount" :confirmHandler="confirmOperateAccount" :cancelHandler="clearForm" :width="600">
            <el-form :model="operateAccountParams" :rules="rules" ref="ruleForm" label-width="110px" class="form-box">
                <el-form-item label="账号名称" prop="nickname">
                    <el-input
                        type="text"
                        v-model="operateAccountParams.nickname"
                        placeholder="请输入账号名称"
                        autocomplete="off"
                        minlength="3"
                        maxlength="20"
                    ></el-input>
                </el-form-item>
                <el-form-item label="账号" prop="username">
                    <el-input
                        type="text"
                        v-model="operateAccountParams.username"
                        placeholder="请输入账号"
                        autocomplete="off"
                        minlength="3"
                        maxlength="10"
                    ></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input
                        type="text"
                        v-model="operateAccountParams.password"
                        placeholder="请输入密码"
                        autocomplete="off"
                        maxlength="12"
                    ></el-input>
                </el-form-item>
                <el-form-item label="所属鉴定中心" prop="check_center_id">
                    <el-select v-model="operateAccountParams.check_center_id" placeholder="请选择鉴定中心">
                        <el-option v-for="item in identifySelectData" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="上传头像" prop="avatar">
                    <div class="avatar-box" v-if="operateAccountParams.avatar">
                        <img class="avatar-img" :src="operateAccountParams.avatar" alt="" />
                        <i @click="clearLogo" class="iconfont">&#xe651;</i>
                    </div>
                    <el-upload
                        v-else
                        class="logo-uploader"
                        action="school/upload"
                        :headers="headersParams"
                        name="avatar"
                        :show-file-list="false"
                        :on-success="handleCoverSuccess"
                        :before-upload="beforeCoverUpload"
                    >
                        <i class="el-icon-plus logo-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
        </Dialog>
        <Dialog ref="deleteAccount" title="删除鉴定中心账号" :confirmHandler="confirmDeleteIdentify" :cancelHandler="clearForm">
            <div class="">删除后不可恢复</div>
        </Dialog>
    </div>
</template>

<script>
import Dialog from "components/admin/common/Dialog";
import TableListPageTemplate from "components/admin/common/TableListPageTemplate";
import { IdentifyAccountList, IdentifyCenterList, IdentifyAccountOperate, IdentifyAccountDelete } from "@/utils/apis";
import { setTimeout } from "timers";

export default {
    name: "IdentifyAccountManage",
    data() {
        let verifyPwd = (ruls, value, callback) => {
            if (value === "" || value === undefined || value === null) {
                callback();
            } else {
                if (value.length < 6 || value.length > 12) {
                    callback(new Error("密码长度要在6-12位之间"));
                } else {
                    callback();
                }
            }
        };
        return {
            tableData: [],
            identifyList: [],
            operateAccountParams: {
                nickname: "",
                username: "",
                password: "",
                check_center_id: "",
                avatar: "",
            },
            getAccountListParams: {
                paging: 1,
                page: 1,
                pageSize: 10,
            },
            totalCount: 1,
            rules: {
                nickname: [{ required: true, message: "请输入账号名称", trigger: "blur" }],
                username: [{ required: true, message: "请输入账号", trigger: "blur" }],
                password: [{ validator: verifyPwd, trigger: "blur" }],
                check_center_id: [{ required: true, message: "请选择鉴定中心", trigger: "blur" }],
            },
            headersParams: {
                Authorization: localStorage.getItem("adminToken") || "",
            },
            isEdit: false,
        };
    },
    components: {
        Dialog,
        TableListPageTemplate,
    },
    mounted() {
        this.getAccountList();
        this.getIdentifyCenterList();
    },
    computed: {
        txtTitle() {
            return this.isEdit ? '编辑账号' : '创建账号'
        },
        templateConfig() {
            return {
                tableData: this.tableData,
                rightConfig: {
                    text: "添加账号",
                    handler: this.addAccount,
                },
                tableConfig: [
                    { label: "账号名称", prop: "nickname" },
                    { label: "头像", prop: "avatar", isImg: true },
                    // { label: "鉴定中心", prop: "check_center_id" },
                    { label: "鉴定中心", prop: "name" },
                    { label: "账号", prop: "username" },
                    { label: "操作", width: 300, align: "center" },
                ],
            };
        },
        identifySelectData() {
            let { identifyList } = this;
            return identifyList.map((item, index) => ({
                label: item.name,
                value: item.id,
            }));
        },
    },
    watch: {
        "getAccountListParams.page"() {
            this.getAccountList();
        },
    },
    methods: {
        async getAccountList() {
            let res = await IdentifyAccountList(this.getAccountListParams);
            if (res.code == 200) {
                this.tableData = res.data.list;
                this.totalCount = res.data.total;
            } else {
                this.$message.error(res.msg);
            }
        },
        async getIdentifyCenterList() {
            let res = await IdentifyCenterList({ paging: "0" });
            if (res.code == 200) {
                this.identifyList = res.data.list;
            } else {
                this.$message.error(res.msg);
            }
        },
        addAccount() {
            this.$refs.operateAccount.open();
        },
        clearForm() {
            this.isEdit = false;
            let { operateAccountParams } = this;
            Object.keys(operateAccountParams).forEach((item) => {
                operateAccountParams[item] = "";
            });
            this.$refs.operateAccount.close();
        },
        confirmOperateAccount(formName) {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    let res = await IdentifyAccountOperate(this.operateAccountParams);
                    if (res.code == 200) {
                        this.$refs.operateAccount.close();
                        this.$message.success(res.msg);
                        this.getAccountList();
                        this.clearForm();
                    } else {
                        this.$message.error(res.msg);
                    }
                } else {
                    return false;
                }
            });
        },
        editAccount(item) {
            this.isEdit = true;
            this.operateAccountParams = {
                ...item,
            };
            this.addAccount();
        },
        deleteAccount(item) {
            this.operateAccountParams.check_center_id = item.id;
            this.$refs.deleteAccount.open();
        },
        async confirmDeleteIdentify() {
            let res = await IdentifyAccountDelete({
                id: this.operateAccountParams.check_center_id,
            });
            if (res.code == 200) {
                this.getAccountList();
                this.$refs.deleteAccount.close();
                this.$message.success(res.msg);
            } else {
                this.$message.error(res.msg);
            }
        },
        //upload
        handleCoverSuccess(res, file) {
            if (res.code === 200) {
                console.log();
                this.operateAccountParams.avatar = res.data.src; // 有域名
                this.$message.success("头像上传成功");
            } 
            // else {
            //     this.$message.error("头像上传失败，请稍后再试~");
            // }
        },
        /**验证图片格式 */
        beforeCoverUpload(file) {
             const isLt2M = file.size/1024/1024 <2
            const isImg =
                file.name.toLowerCase().endsWith(".jpg") ||
                file.name.toLowerCase().endsWith(".jpeg") ||
                file.name.toLowerCase().endsWith(".png");
            if (!isImg) {
                this.$message.error("上传图片只能是 jpg 或 jpeg 或 png 格式!");
                return false;
            }else if(!isLt2M){
                  this.$message.error("上传图片大小不能超过2M！");
            }
            return isImg;
        },
        clearLogo() {
            this.operateAccountParams.avatar = "";
        },
    },
};
</script>

<style scoped lang="scss">
.identify-account-manage {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .table {
        flex: 1;
        overflow: hidden;
    }
    .paging-box {
        padding: 20px 0;
        text-align: center;
    }
    .form-box {
        padding-top: 20px;
        .el-form-item {
            display: flex;
            align-items: center;
        }
        ::v-deep .el-form-item__label {
            margin-bottom: 0;
            margin-right: 20px;
        }
        ::v-deep .el-form-item__content {
            flex: 1;
            margin-left: 0 !important;
        }
    }
    .add-account {
        margin-bottom: 20px;
        text-align: end;
    }
}

.avatar-box {
    width: 80px;
    height: 80px;
    box-sizing: border-box;
    border: 1px solid #eeeeee;
    position: relative;

    img {
        width: 100%;
        height: 100%;
    }

    .iconfont {
        line-height: 1;
        font-size: 14px;
        color: #dcdcdc;
        position: absolute;
        right: -20px;
        top: 0;
        cursor: pointer;
    }
}
.logo-uploader {
    ::v-deep.el-upload {
        width: 80px;
        height: 80px;
        background: #eeeeee;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo-uploader-icon {
            font-size: 32px;
            color: #a0a0a0;
        }
    }
}
.avatar-box {
    width: 80px;
    height: 80px;
    .avatar-img {
        width: 100%;
        height: 100%;
        vertical-align: middle;
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
::v-deep .form-box .el-form-item__label {
    padding: 0;
    font-size: 16px !important;
    white-space: nowrap;
}
::v-deep .form-box .el-form-item__content {
    text-align: start;
}
</style>
